import React, { useEffect, useRef, useState } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider } from 'react-scroll-parallax';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import HelmetComponent from '../components/helmetcomponent';
import Layout from '../components/layout';
import VerticalDonationBar from '../components/verticaldonationbar';
import '../template-styles/giving-tuesday-2024.scss';
import SponsorModal from '../components/sponsorModal';
import LGLForm from '../components/LGLForm';


export default function Template({ data }) {
  const pageData = data.allContentfulGivingTuesday2020.edges[2].node;
  const {
    bannerEmbeddedUrl,
    bannerImageThumbnail,
    sponsorAMomImage,
    hopefulJudith,
    sponsorNowImage,
    bodyTitle,
    bodyTitle2,
    bodyDescription,
    bodyDescription2,
    videoDescription,
    buttonDonateToday
  } = pageData;

  const [sponsorModalShow, setSponsorModalShow] = useState(false);

  const videoDescriptionOptions = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="video-description">{children}</p>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="sub-title text-purple">{children}</h2>,
      [BLOCKS.HEADING_6]: (node, children) => <p className="video-description text-purple"><b>{children}</b></p>,
    }
  };

  return (
    <ParallaxProvider>
        <Layout nav="colored-nav">
          <HelmetComponent title="Giving Tuesday" />
          <section className="gt24-banner">
            <Img alt="giving-tuesday" className="w-100" fluid={bannerImageThumbnail.fluid} />
          </section>

          <section className="gt24-body container">
            <div className="body-container">
              <div className="text-section">
                <div className="d-flex align-items-center gap-2">
                  <div className="giving-tuesday-logo-container">
                    <div className="giving-tuesday-logo" />
                  </div>
                  <h1 className="title mb-0 size-22">{bodyTitle.bodyTitle}</h1>
                </div>
                <h2 className="title mt-9 text-purple">{bodyTitle2.bodyTitle2}</h2>
                <p className="description">{bodyDescription.childMarkdownRemark.rawMarkdownBody}</p>
              </div>
            </div>

            <div className="embed-container">
              <iframe
                id="giving-tuesday-2024-video"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                data-src={bannerEmbeddedUrl}
                title="Pearls of Value"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>

            <div className="videoDescription">
              {documentToReactComponents(videoDescription.json, videoDescriptionOptions)}
              <div className="text-center">
                <img src={`${sponsorAMomImage.file.url}?w=500`} alt="giving-tuesday" className="sponsor-a-mom-image" />
              </div>
            </div>

            <div className="body-container">
              <div className="text-section">
                <p className="description">{bodyDescription2.childMarkdownRemark.rawMarkdownBody}</p>
              </div>
            </div>
            <div className="sponsor-link-container" id="sponsorNow">
              <a className="sponsor-link" onClick={() => {
                setSponsorModalShow(true);
              }}>
                {buttonDonateToday}
              </a>
              <img src={`${sponsorNowImage.file.url}?w=200`} alt="giving-tuesday" />
            </div>
          </section>
          <section className="gt24-banner">
            <Img alt="giving-tuesday" className="w-100" fluid={hopefulJudith.fluid} />
          </section>
        </Layout>
        <SponsorModal id='sponsor-modal' modalShow={sponsorModalShow} onHide={() => {
          window.location.hash = "";
          setSponsorModalShow(false);
          setTimeout(() => {
            window.location.hash = "sponsorNow";
          }, 200);
        }} title="Sponsor Now" leftSide={<Img alt="giving-tuesday" className="w-100 d-none d-lg-block" fluid={bannerImageThumbnail.fluid} />} body={sponsorModalShow && <LGLForm formId={"_1bMxrPdIoqC4sUjblzrBQ"} initialHeight={767} formPrefix={"https://secure.lglforms.com/form_engine"} />} />
    </ParallaxProvider>
  );
}

export const GivingTuesday2020PageQuery = graphql`
  query GivingTuesday2024Page($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
      }
    }
    allContentfulGivingTuesday2020 {
      totalCount
      edges {
        node {
          bannerEmbeddedUrl
          sponsorNowImage {
            fluid(maxWidth: 200) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
          bannerImageThumbnail {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
          title
          bodyDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          bodyTitle {
            bodyTitle
          }
          bodyTitle2 {
            bodyTitle2
          }
          bodyDescription2 {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          videoDescription {
            json
          }
          buttonDonateToday
          goalAmount
          donationGoalAmount
          sponsorAMomImage {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
          hopefulJudith {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid
            }
            file {
              url
            }
          }
        }
      }
    }
  }
`;
