import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Close from '../../img/icon-close.svg';

const SponsorModal = ({ id, modalShow, onHide, title, body, leftSide }) => {
  return (
    <Modal
      id={id}
      show={modalShow}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='h-100'
    >
      <Modal.Body className="d-flex p-0 h-98">
        {leftSide}
        <div id="sponsor-now-body" className='pb-3 pt-3 w-100'>
          {body}
        </div>
        <a onClick={onHide} className="position-absolute right-0">
          <img className="socmed-icons twitter-icon" src={Close} alt="" />
        </a>
        </Modal.Body>
    </Modal>
  );
};

export default SponsorModal;
